<script lang="ts">
	import Header from "./reusable/Header.svelte"
	import Footer from "./reusable/Footer.svelte"
	import { RadioController } from "./radio/RadioController"
	import RadioPlayer from "./radio/RadioPlayer.svelte"
	import { Overlay } from "svelte-comps/overlay"

	interface Props {
		id?: string | undefined
	}

	let { id = undefined }: Props = $props()

	$effect(() => {
		if (id) RadioController.connectToAudioCode(id)
	})
</script>

<svelte:head>
	<script src="https://use.ntpjs.org/ntp.js" async defer></script>
</svelte:head>

<Header />
<main>
	<RadioPlayer />
</main>
<Footer />

<Overlay />

<style>
</style>
