<script lang="ts">
	import { ArrowForward } from "svelte-comps/icons"
	import { RadioController, ResponseError } from "./RadioController"
	import LoadingBar from "./LoadingBar.svelte"

	let id: string = $state("")
	let isLoading: boolean = $state(false)
	let errorMessage: string | undefined = $state()

	async function setCode(e: SubmitEvent) {
		e.preventDefault()
		isLoading = true
		id = id.toUpperCase()
		try {
			errorMessage = undefined
			await RadioController.connectToAudioCode(id, true)
		} catch (e) {
			if (e instanceof ResponseError) errorMessage = e.message
		}
		isLoading = false
	}
</script>

<form class="card" onsubmit={setCode}>
	{#if isLoading}
		<LoadingBar />
	{/if}

	<div class="flex-row space-apart">
		<div class="connected-to">
			<span class="small heading">Connected to</span>
			<h3 class="audio-code">NONE</h3>
		</div>
		<span class="last-update"> disconnected </span>
	</div>

	<div class="input-container connect-to">
		<label for="audio-code-input heading">Enter Code </label>
		<div class="input-wrapper">
			<ArrowForward />
			<input
				id="audio-code-input"
				type="text"
				bind:value={id}
				placeholder="LUXE"
			/>
		</div>
	</div>

	<div class="info {errorMessage ? 'error' : ''}">
		{#if errorMessage}
			{errorMessage}
		{:else if isLoading}
			Connecting to radio session {id}...
		{/if}
	</div>

	<div class="player-bottom">
		<span class="playback-offset"></span>
		<div class="button-container">
			<button
				class="play-button outline-button"
				type="submit"
				disabled={id.length != 4 || isLoading}>Begin Listening</button
			>
		</div>
	</div>
</form>

<style>
	label {
		color: var(--color-text-light);
		width: 100%;
		line-height: 1em;
	}

	input::placeholder {
		color: var(--color-text);
	}

	input {
		color: var(--color-main);
		font-size: var(--h1);
		border-radius: 0;
		line-height: 1.5em;
		width: 100%;
		padding: 0;
		line-height: 1em;
	}
</style>
